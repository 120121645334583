.container {
  display: flex;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  justify-content: space-around;
  background-color: rgb(20, 20, 20);
}

.button {
  width: 100%;
  background-color: black;
  color: white;
  border: 1px solid white;
}

.button:hover {
  border: white;
  background-color: white;
  border: 1px solid black;
  color: black;
}

.h3,
.p {
  color: white;
}

@media only screen and (max-width: 400px) {
  .container {
    padding: 50px;
  }
}
