.card {
  background-color: white;
  padding: 20px;
}

.row {
  cursor: pointer;
}

.selected {
  background-color: rgb(240, 240, 240);
}
