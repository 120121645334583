/*
daniel.kwok 5/5/2022
Copied form https://niemvuilaptrinh.medium.com/how-to-create-the-html-css-frosted-glass-effect-1797fbaf465e
*/
.frosted {
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  position: relative;
  z-index: 1;
  background: inherit;
  overflow: hidden;
}
.frosted::before {
  content: "";
  position: absolute;
  background: inherit;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5);
  filter: blur(40px);
  margin: -20px;
}

.surveyTemplateContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

@media screen and (max-width: 1000px) {
  .surveyTemplateContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column
  }
}