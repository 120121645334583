.container {
  display: flex;
  width: 100%;
  min-height: 100vh;
  justify-content: space-around;
  background-color: black;
  padding: 20px;
}

.question_container {
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-bottom: 30%;
  margin-top: 30%;
}

.question_options {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.footer_container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin: 10px;
}

.footer_buttons_container {
  display: flex;
}

@media only screen and (max-width: 400px) {
  .question_container {
    height: 90vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

  .question_options {
    flex-direction: column;
    width: 100%;
  }

  .questions_button {
    width: 100%;
  }

  .footer_container {
    flex-direction: column;
    justify-content: space-between;
  }

  .footer_buttons_container {
    justify-content: space-between;
    width: 100%;
  }
}
