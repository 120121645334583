.card_container {
  display: flex;
  gap: 20px;
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
  height: 100%;
  flex-direction: row;
}

.card {
  display: flex;
  max-height: 300px;
  flex: 2;
  justify-content: center;
  border: 0px;
}

@media only screen and (max-width: 400px) {
  .card_container {
    width: "100%";
  }

  .card {
    display: flex;
    max-height: 300px;
    width: 100%;
  }
}
